import { useEffect, useState, useRef } from 'react';
import { Spinner, Modal } from 'react-bootstrap'
import { useLocation } from "react-router-dom";
import { getApps, initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, doc, getDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import VerifiedUser from './components/VerifiedUser';
import Loader from './components/Loader';
import Logo from './components/Logo';
import FormEnterRoom from './components/FormEnterRoom';

const firebaseConfig = {
    apiKey: "AIzaSyC9yxBrCC9dweJDxYi9teaSSQcd5s1Z7uQ",
    authDomain: "oloop-studio-286216.firebaseapp.com",
    // databaseURL: "https://oloop-studio-286216.firebaseio.com",
    projectId: "oloop-studio-286216",
    storageBucket: "oloop-studio-286216.appspot.com",
    // messagingSenderId: "",
    // appId: "",
    // measurementId: ""
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const storage = getStorage(app);
const functions = getFunctions(app, "asia-southeast2");
const auth = getAuth(app);


// Initialize Firebase

function App() {
    const [userData, setUserData] = useState(null);
    const [areaData, setAreaData] = useState({ name: "" })
    const [loadingArea, setLoadingArea] = useState(false)
    const [disableInput, setDisableInput] = useState(false)
    const [tempUserId, setTempUserId] = useState("");
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [modalError, setModalError] = useState(false)
    const inputRef = useRef();

    useEffect(() => {
        if (window.location.hostname === "localhost") {
            connectFirestoreEmulator(db, "127.0.0.1", 8080);
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
            connectAuthEmulator(auth, "http://127.0.0.1:9099");
        }
    }, [])

    const search = useLocation().search;
    const areaId = new URLSearchParams(search).get("areaId");

    const handleClose = (event, reason) => {
        setUserData(null)
        setTempUserId("")
        setDisableInput(true)
        setLoadingArea(true);
        setOpen(false);
        setModalError(false)
        window.location.reload();
    };

    const handleChange = (event) => {
        setTempUserId(event.target.value)
    };

    console.log({
        db
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const data = new FormData(event.currentTarget);
        var qrCode = data.get('formId');
        var areaIdUser = areaId;
        // var checkUserOnSite = firebase.app().functions('asia-southeast2').httpsCallable("checkUserOnsite");
        try {

            const checkUserOnSite = httpsCallable(functions, "checkUserOnsite");
            const responseData = await checkUserOnSite({
                qrCode: qrCode,
                areaId: areaIdUser
            });
            console.log(responseData);
            if (responseData.data.success == true) {
                setUserData(responseData.data.data)
                setOpen(true);

                setTimeout(() => {
                    handleClose()
                }, 3000)
            } else {
                setModalError(true)

                setTimeout(() => {
                    handleClose()
                }, 3000)
            }

        } catch (error) {
            console.error("error check user on site: ", error);
        }
        setTempUserId("")
        setLoading(false)
    };

    const fetchDataArea = async () => {
        setLoadingArea(true)
        try {
            console.log("areaId: ", areaId)
            const areaRef = doc(db, "restrictionArea", areaId)
            const areaSnap = await getDoc(areaRef)
            if (areaSnap.exists()) {
                const tempAreaData = areaSnap.data();
                setAreaData(tempAreaData);
            } else {
                setAreaData("")
                return;
            }
            setLoadingArea(false)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (areaId) {
            fetchDataArea()
        } else {
            setLoadingArea(false)
        }
    }, [areaId])

    useEffect(() => {
        if (modalError) {
            setDisableInput(true)
        } else {
            setDisableInput(false)
        }
    }, [modalError, disableInput])

    console.log("userData: ", userData)

    return (
        <>
            {
                open ?
                    <VerifiedUser userData={userData} />
                    :
                    <div className="vh-100">
                        <div className="h-100 d-flex justify-content-center align-items-center px-0 w-100 flex-column">
                            {
                                loadingArea ?
                                    <Loader />
                                    :
                                    <>
                                        {
                                            areaData.name === "" ?
                                                <Logo />
                                                :
                                                <FormEnterRoom
                                                    handleSubmit={handleSubmit}
                                                    areaData={areaData}
                                                    disableInput={disableInput}
                                                    inputRef={inputRef}
                                                    tempUserId={tempUserId}
                                                    handleChange={handleChange}
                                                    loading={loading}
                                                />
                                        }
                                    </>
                            }
                        </div>
                    </div>
            }

            {/* <VerifiedUser userData={userData} /> */}

            {/* <div className="vh-100">
                <div className="h-100 d-flex justify-content-center align-items-center px-0 w-100 flex-column">
                    <FormEnterRoom
                        handleSubmit={handleSubmit}
                        areaData={areaData}
                        disableInput={disableInput}
                        inputRef={inputRef}
                        tempUserId={tempUserId}
                        handleChange={handleChange}
                        loading={loading}
                    />
                </div>
            </div> */}

            <Modal contentClassName="bg-modal-error" size="lg" show={modalError} centered onHide={handleClose}>
                <Modal.Body>
                    <div className="py-4">
                        <div className="text-center mb-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="white" className="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                        <div className="text-center text-white mb-4 text-uppercase" style={{ fontFamily: "EixampleVillaBold", fontSize: 50 }}>Access Denied</div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default App;
