const FormEnterRoom = ({ 
    handleSubmit, 
    areaData, 
    disableInput, 
    inputRef,
    tempUserId,
    handleChange,
    loading 
}) => {
    return (
        <form className="w-100" onSubmit={handleSubmit}>
            <div className="position-absolute top-0 start-50 translate-middle-x mx-auto text-center pt-5">
                <img className="img-fluid" style={{ width: "30%" }} src="/logo.png" alt="" />
            </div>
            <div className="row justify-content-center w-100 gx-0 mb-3">
                <div className="col-12 text-center">
                    <>
                        <p className="form-label text-center w-100 mb-2 text-breakout-room">{areaData.name || ""}</p>
                        {/* <p className="form-label text-center w-100 mb-2 text-breakout-room">Test</p> */}
                    </>
                </div>
            </div>
            <div className="row justify-content-center w-100 gx-0 mb-3">
                <div className="col-xl-3 col-lg-4 col-8 text-center">
                    <input
                        disabled={disableInput ? true : false}
                        onBlur={({ target }) => target.focus()}
                        ref={inputRef}
                        name="formId"
                        id="formId"
                        required
                        autoFocus
                        type="text"
                        className="form-control w-100 mx-auto shadow-none"
                        style={{borderColor: "black",borderRadius: "14px"}}
                        value={tempUserId}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row justify-content-center w-100 gx-0">
                <div className="col-xl-3 col-lg-4 col-8 text-center">
                    <button disabled={loading ? true : false} style={{ borderRadius: "14px" }} className="btn btn-info w-100 btn-submit" type="submit">
                        {loading ? "Loading..." : "Submit"}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default FormEnterRoom;