const VerifiedUser = ({ userData }) => { 
    return (
        <>
            <div className="position-absolute top-0 start-50 translate-middle-x mx-auto text-center pt-5">
                <img className="img-fluid" style={{ width: "30%" }} src="/logo.png" alt="" />
            </div>
            <div className="vh-100">
                <div className="h-100 d-flex justify-content-center align-items-center px-0 w-100 flex-column">
                    <div className="text-center mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="#2CB14A" className="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                        </svg>
                    </div>
                    <p className="text-center text-verified mb-0 text-uppercase">Access Granted</p>
                    <p className="text-center text-user mb-0 text-uppercase" style={{ lineHeight: "4rem" }}>{userData.printedName ? userData.printedName : userData.name}</p>
                    {/* <p className="text-center text-user mb-0 text-uppercase" style={{ lineHeight: "4rem" }}>Handhika Dwi</p> */}

                    <p className="text-center text-company mb-0">{userData.departmentName}</p>
                    {/* <p className="text-center text-company mb-0">Oloop Studio</p> */}
                </div>
            </div>
        </>
    )
}

export default VerifiedUser